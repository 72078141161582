.desktop-only{
    display: block !important;
}

.mobile-only{
    display: none !important;
}

.outer-box{
    display: flex;
    justify-content: center;
    align-items:center;
}

.wrap{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

@media (max-width: 1280px){

    .desktop-only{
        display: none !important;
    }

    .mobile-only{
        display: block !important;
    }

    .wrap{
        position: relative !important;
        width: 100%;
        margin: auto;
    }

    .box-position{
        margin-left: 0 !important;
        width: 100% !important;
    }

    /* .inner-box{
        width: 100% !important;
        border-radius: 0px !important;
        height: 50vh !important;
    } */

    .source-img{
        max-width: 100% !important;
        width: 100% !important;
    }

    .close-btn-wrap{
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: 0 !important;
        position: absolute;
        width: 93% !important;
        height: 35px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .close-btn{
        left: auto !important;
        position: relative !important;
        width: 85px !important;
        height: 35px !important;
        background: #f26722 !important;
        color: #fff;
        border-radius: 10px 10px 0px 0px !important;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }

    .close-text{
        margin-left: 10px !important;
    }

    .open-btn{
        /* position: relative; */
        margin: auto;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .white{
        color:#fff !important;
    }

}