:root {
  --rsbs-max-w: 700px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
}

.swiper {
  width: 100%;
  height: 100%;
  max-width: 600px;
}